import { AppMenu } from "../../../pages/app/app-menu";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { Container, Paper } from "@mui/material";
import { Box } from "@mui/system";
import { NavigationBar } from "../../common/NavigationBar/NavigationBar";

const WebpanelPage: FC = () => {
  return (
    <>
      <NavigationBar />
      <Container>
        <Box sx={{ m: 2, display: "flex" }}>
          <Box component="nav" sx={{ width: 300, pr: 1 }}>
            <AppMenu />
          </Box>
          <Paper
            elevation={2}
            // variant={"outlined"}
            sx={{ flexGrow: 1, p: 2 }}
            component="main"
          >
            <Outlet />
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default WebpanelPage;
