export type Color = string;
type Swatch = { [key in SwatchLevels]: Color };
type ColorsSpec = { [key in Swatches]: Swatch };
enum Swatches {
  RED = "red",
  BLUE = "blue",
}

enum SwatchLevels {
  L50 = "50",
  L100 = "100",
  L200 = "200",
  L300 = "300",
  L400 = "400",
  L500 = "500",
  L600 = "600",
  L700 = "700",
  L800 = "800",
  L900 = "900",
  A100 = "A100",
  A200 = "A200",
  A400 = "A400",
  A700 = "A700",
}

const Colors: ColorsSpec = {
  [Swatches.BLUE]: {
    [SwatchLevels.L50]: "#e4e7ea",
    [SwatchLevels.L100]: "#bbc2cc",
    [SwatchLevels.L200]: "#8e9aaa",
    [SwatchLevels.L300]: "#617287",
    [SwatchLevels.L400]: "#3f536e",
    [SwatchLevels.L500]: "#1d3554",
    [SwatchLevels.L600]: "#1a304d",
    [SwatchLevels.L700]: "#152843",
    [SwatchLevels.L800]: "#11223a",
    [SwatchLevels.L900]: "#0a1629",
    [SwatchLevels.A100]: "#6699ff",
    [SwatchLevels.A200]: "#3377ff",
    [SwatchLevels.A400]: "#0055ff",
    [SwatchLevels.A700]: "#004de6",
  },
  [Swatches.RED]: {
    [SwatchLevels.L50]: "#f3e6e7",
    [SwatchLevels.L100]: "#e0c1c4",
    [SwatchLevels.L200]: "#cc989c",
    [SwatchLevels.L300]: "#b86f74",
    [SwatchLevels.L400]: "#a85057",
    [SwatchLevels.L500]: "#993139",
    [SwatchLevels.L600]: "#912c33",
    [SwatchLevels.L700]: "#86252c",
    [SwatchLevels.L800]: "#7c1f24",
    [SwatchLevels.L900]: "#6b1317",
    [SwatchLevels.A100]: "#ffa1a5",
    [SwatchLevels.A200]: "#ff6e74",
    [SwatchLevels.A400]: "#ff3b43",
    [SwatchLevels.A700]: "#ff222a",
  },
};

export default Colors;