import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getDatabase, ref, set } from "@firebase/database";
import { ChartNotification } from "./index";
import { DateTimePicker, LoadingButton } from "@mui/lab";

const { DateTime } = require("luxon");

export const EditNotificationDialog = ({
  item,
  handleClose,
  handleSave,
}: {
  item: ChartNotification | null;
  handleClose: () => void;
  handleSave: (item: ChartNotification) => void;
}) => {
  let [title, setTitle] = useState("");
  let [start, setStart] = useState<typeof DateTime | null>();
  let [end, setEnd] = useState<typeof DateTime | null>();
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!item) {
      setLoading(false);
      setTitle("");
      setStart(null);
      setEnd(null);
      return;
    }
    setTitle(item.title);
    setStart(item.startDayjs);
    setEnd(item.endDayjs);
  }, [item]);

  const save = () => {
    if (!item || !item.id || item.id == "") return;
    if (!end || !start || end?.diff(start, "days") < 0) {
      return alert("Please input valid start and end times.");
    }
    setLoading(true);
    let newItem: ChartNotification = {
      id: item.id,
      title,
      start: start?.setZone("UTC", { keepLocalTime: true }).toISO(),
      end: end?.setZone("UTC", { keepLocalTime: true }).toISO(),
      startSeconds: start?.toMillis(),
    };
    set(ref(getDatabase(), "notifications/" + item.id), newItem)
      .then(() => {
        handleSave({
          ...newItem,
          startDayjs: start ?? undefined,
          endDayjs: end ?? undefined,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Dialog
        open={!!item}
        onClose={handleClose}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Edit notification</DialogTitle>
        <DialogContent>
          <br />
          <TextField
            id="outlined-basic"
            fullWidth
            label="Title"
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <br />
          <br />
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            label="From"
            value={start}
            ampm={false}
            inputFormat={"dd-LL-yyyy HH:mm"}
            views={["year", "month", "day", "hours"]}
            onChange={(newValue) => {
              setStart(newValue);
            }}
          />{" "}
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            label="to"
            value={end}
            ampm={false}
            inputFormat={"dd-LL-yyyy HH:mm"}
            views={["year", "month", "day", "hours"]}
            onChange={(newValue) => {
              setEnd(newValue);
            }}
          />
          <br />
          <br />
          Duration: {Math.round(end?.diff(start, "days").as("days"))} days
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={loading}>
            Cancel
          </Button>
          <LoadingButton
            onClick={save}
            variant="contained"
            autoFocus
            loading={loading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
