import * as React from "react";
import "../styles/globals.scss";

import { NavigationBar } from "../components/common/NavigationBar/NavigationBar";
import { AuthProvider } from "../providers/auth";
import {
  CircularProgress,
  Container,
  Grid,
  Modal,
  ThemeProvider,
} from "@mui/material";
import theme from "../styles/theme";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { UserContext } from "../contexts/user";
import { Box } from "@mui/system";
import { Home } from "./home/Home";
import { Suspense, useContext } from "react";
import { SelectAppPage } from "./app/select-app";
import { LocalizationProvider } from "@mui/x-date-pickers";
import AppStoreReferral, { appStoreLinks } from "./app/app-store-referral";
import FullPageLoader from "../components/common/Loader/FullPageLoader";
import WebpanelPage from "../components/webpanel/WebpanelPage/WebpanelPage";
import Texts from "./app/texts";
import News from "./app/news";
import Users from "./app/users/Users";
import Notifications from "./app/notifications";

export const useAuth = () => React.useContext(UserContext);

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  let auth = useAuth();
  let params = useParams();
  let navigate = useNavigate();

  if (auth.isLoading) {
    return <FullPageLoader />;
  }

  if (!auth.user) {
    console.log("hier", { params, auth });

    if (params.app && Object.keys(appStoreLinks).includes(params.app)) {
      return <AppStoreReferral />;
    }

    navigate("/");
    return <>Not authorized</>;
  }

  console.log("return requireauth", { params, auth });
  if (
    auth.signedInToApp &&
    params.app &&
    params.app !== auth.user.appConfig.app
  ) {
    console.log("return refresh", { params });
    console.log("Changed app reloading page.");
    window.location.reload(); //@TODO: Can this be done without reloading?
  }

  if (!auth.user || !auth.signedInToApp) {
    console.log("return please log in", { auth, params });
    // return <Navigate to="/login" state={{ from: location }} replace />;
    return (
      <>
        <FullPageLoader
          message={auth.isLoading || auth.user != null ? null : "Please log in"}
        />

        <Modal open={auth.isLoading}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%" }}
          >
            <CircularProgress sx={{ color: "white" }} />
          </Grid>
        </Modal>
      </>
    );
  }

  console.log("return children", { children, params });

  return children;
};

// const WebpanelPage = React.lazy(
//   () => import("../components/webpanel/WebpanelPage/WebpanelPage")
// );
// const Notifications = React.lazy(() => import("./app/notifications/index"));
// // @ts-ignore
// const Texts = React.lazy(() => import("./app/texts"));
// // @ts-ignore
// const Users = React.lazy(() => import("./app/users/Users"));
// // @ts-ignore
// const News = React.lazy(() => import("./app/news/index"));

export const App = () => (
  <>
    <BrowserRouter>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <Suspense fallback={<FullPageLoader />}>
              <Routes>
                <Route
                  path="/app/:app"
                  element={
                    <RequireAuth>
                      <WebpanelPage />
                    </RequireAuth>
                  }
                >
                  7
                  <Route path="notifications" element={<Notifications />} />
                  <Route path="texts" element={<Texts />} />
                  <Route path="news" element={<News />} />
                  <Route path="users" element={<Users />} />
                  <Route
                    index
                    element={
                      <div>Welcome to the Live Megawatt App Portal...</div>
                    }
                  />
                </Route>
                <Route path="select-app" element={<SelectAppPage />} />
                <Route index element={<Home />} />
              </Routes>
            </Suspense>
          </LocalizationProvider>
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>
  </>
);
