import React, { FC, useState } from "react";
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { getAuth } from "firebase/auth";
import { useAuth } from "../../App";

type AddUserDialogProps = {
  open: boolean;
  onClose: () => React.Dispatch<boolean>;
};

export const AddUserDialog: FC<AddUserDialogProps> = ({
  open,
  onClose,
}: AddUserDialogProps) => {
  const [error, setError] = useState<string | null>(null);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const auth = useAuth();

  const resetForm = () => {
    setError("");
    setEmail("");
  };

  const onSave = async (e) => {
    e.preventDefault();

    setLoading(true);
    setError(null);

    const app = auth.user.appConfig.app;
    const token = await getAuth().currentUser.getIdToken();
    await fetch(
      "https://ledger.livemegawatt.com/add-user",
      // "http://localhost:3000/add-user",
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          email,
          app,
        }),
        redirect: "follow",
      }
    )
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        switch (result.status) {
          case "success":
            resetForm();
            onClose();
            break;
          case "already-added":
            setError("This user is already added to the app.");
            break;
          case "unknown":
          case "error":
          default:
            setError("Unable to add this user to the app.");
            break;
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return false;
  };

  // getAuth().currentUser.getIdToken().then(console.log);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add user</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
        <form onSubmit={onSave}>
          <TextField
            required
            fullWidth
            margin="normal"
            id="email"
            name="email"
            label="Email"
            autoFocus
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <LoadingButton
            type="submit"
            variant="contained"
            loading={loading}
            sx={{ mt: 3, mb: 2 }}
          >
            Submit
          </LoadingButton>
        </form>
      </DialogContent>
    </Dialog>
  );
};
