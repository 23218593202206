import {FC, useEffect, useState} from "react";
import {InfoText} from "../texts";
import {
    Button,
    Chip,
    CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import {getApp} from "firebase/app";
import {
    collection,
    getDocs,
    getFirestore,
    query,
    where,
} from "firebase/firestore";
import {Box} from "@mui/system";
import {AddUserDialog} from "./AddUserDialog";
import {Edit, LockReset} from "@mui/icons-material";
import {useAuth} from "../../App";
import {RemoveUserDialog} from "./RemoveUserDialog";

const {DateTime} = require("luxon");

type LedgerUser = {
    email: string;
    id: string;
    lastSignedIn: string;
    admin: boolean;
};

const Users: FC = () => {
    const [loading, setLoading] = useState(false);
    const [showUserDialog, setShowUserDialog] = useState(false);
    const [removeUserIds, setRemoveUserIds] = useState<string[]>([]);
    const [users, setUsers] = useState<{ [key: string]: LedgerUser }>({});
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const auth = useAuth();

    useEffect(() => {
        setLoading(true);

        const app = auth.user.appConfig.app;

        getDocs(
            query(
                collection(getFirestore(getApp("ledger")), "apps/" + app + "/users"),
                where("email", "!=", "")
            )
        )
            .then((querySnapshot) => {
                let users = {};
                querySnapshot.forEach((doc) => {
                    users[doc.id] = doc.data();
                    users[doc.id].lastSignedIn = "";
                    if (users[doc.id]["last-signed-in"]) {
                        users[doc.id].lastSignedIn = DateTime.fromSeconds(
                            users[doc.id]["last-signed-in"].seconds
                        ).toLocaleString(DateTime.DATETIME_FULL);
                    }
                    users[doc.id].id = doc.id;
                });
                setUsers(users);

                // Code to select all users:
                // setSelectedUsers(Object.values(users).map((user) => user.id));

                // get(ref(getDatabase(getApp("ledger")), "app/lake-turkana/users"))
                //     .then(function (snapshot) {
                //         console.log(snapshot.val());
                //     if (snapshot.exists()) {
                //         let items = snapshot.val();
                //         for (let key of Object.keys(items)) {
                //             let item = items[key];
                //             if (!item.content && !item[0]) {
                //                 delete items[key];
                //             }
                //         }
                //         setUsers(items);
                //     }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const toggleSelectedUser = (id) => {
        if (selectedUsers.includes(id)) {
            setSelectedUsers(selectedUsers.filter((item) => item !== id));
        } else {
            setSelectedUsers([...selectedUsers, id]);
        }
    }

    return (
        <>
            <h2>Users</h2>

            <Button variant={"contained"} onClick={() => setShowUserDialog(true)}>
                Add user
            </Button>

            <br/>
            <br/>

            {loading ? (
                <CircularProgress/>
            ) : (
                <Table aria-label="users table">
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell>E-mail</TableCell>
                            <TableCell>Last signed in </TableCell>
                            <TableCell style={{width: "200px"}} align="right">
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users &&
                            Object.values(users)
                                // .sort((a, b) =>
                                //     (a.startSeconds ?? 0) - (b.startSeconds ?? 0))
                                .map((item) => (
                                    <TableRow key={item["id"]}>
                                        <TableCell>
                                            <input type={"checkbox"} onChange={() => toggleSelectedUser(item["id"])}
                                                    checked={selectedUsers.includes(item["id"])}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {item.email}
                                            {item.admin && (
                                                <Box sx={{pt: 1}}>
                                                    <Chip label="Administrator" size="small"/>
                                                </Box>
                                            )}
                                        </TableCell>
                                        <TableCell>{item.lastSignedIn}</TableCell>
                                        <TableCell align="right">
                                            {/*<Button variant="outlined" onClick={() => {}}>*/}
                                            {/*    Resend invite*/}
                                            {/*</Button>*/}
                                            <Button variant="outlined" onClick={() => setRemoveUserIds([item.id])}>
                                                Remove
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                    </TableBody>
                </Table>
            )}

            <br/>

            <Button
                onClick={() => setRemoveUserIds(selectedUsers)}
                variant="contained"
                color="error">
                Remove selected users
            </Button>

            <AddUserDialog
                open={showUserDialog}
                onClose={() => setShowUserDialog(false)}
            />
            <RemoveUserDialog
                open={removeUserIds && removeUserIds.length > 0}
                uids={removeUserIds}
                onClose={() => setRemoveUserIds([])}
            />
        </>
    );
};

export default Users;
