import {FC, useContext, useEffect, useState} from "react";

import {User} from "../types/user";
import {UserContext} from "../contexts/user";
import {
    browserSessionPersistence,
    getAuth,
    signInWithCustomToken,
    User as FirebaseUser,
} from "firebase/auth";
import {getApp, getApps, initializeApp} from "firebase/app";
import {LMWAppConfig} from "../types/ledger";
import {getAvailableApps} from "../services/ledger";
import {useNavigate} from "react-router";
import {useLocation, useParams, useSearchParams} from "react-router-dom";

export const AuthProvider: FC = (props) => {
    const [activeAppName, setActiveAppName] = useState<string | null>(null);
    const [signedInToApp, setSignedInToApp] = useState(false);
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const setApp = (app: LMWAppConfig | null) => {
        setUser(Object.assign(user, {appConfig: app}));
    };
    let location = useLocation();

    const processCredential = async (user: FirebaseUser): Promise<true | any> => {
        // console.log("processCredential");
        try {
            const token = await user.getIdToken();
            let apps = await getAvailableApps(token);
            let tag = null;
            let locationParts = [];
            if (location && location.pathname) {
                locationParts = location.pathname.split("/");
                if (locationParts.length >= 3 && locationParts[1] === "app") {
                    tag = locationParts[2];
                }
            }

            if (apps.length == 1) {
                const appConfig = apps[0];
                setUser({
                    id: user.uid,
                    name: user.displayName,
                    email: user.email,
                    apps: apps,
                    appConfig: appConfig,
                });
                navigate("/app/" + apps[0].app);
            } else if(tag !== "" && apps.filter(a => a.app === tag).length > 0) {
                const appConfig = apps.filter(a => a.app === tag)[0];
                setUser({
                    id: user.uid,
                    name: user.displayName,
                    email: user.email,
                    apps: apps,
                    appConfig: appConfig,
                });
                let path = "/app/" + appConfig.app;
                if (locationParts.length > 3) {
                    path += "/" + locationParts.slice(3).join("/");
                }
                navigate(path);
            } else {
                setUser({
                    id: user.uid,
                    name: user.displayName,
                    email: user.email,
                    appConfig: null,
                    apps: apps,
                });
                console.log("nav go /select-app")
                navigate("/select-app");
                setLoading(false);
            }
            return true;
        } catch (e: any) {
            alert("Unable to sign in");
            setLoading(false);
            return e;
        }
    };

    useEffect(() => {
        // console.log("authStateListener effect");
        const authStateListener = getAuth(getApp("ledger"))
            .onAuthStateChanged(async (firebaseUser) => {
                // console.log("authStateChanged: ", firebaseUser);
                if (!firebaseUser) {

                    for (const app of getApps().filter((a) => a.name != "ledger")) {
                        // console.log("Signing out from: ", app);
                        await getAuth(app).signOut();
                    }

                    setUser(null);
                    setLoading(false);
                    // if (typeof window != "undefined") {
                    // if (Router.route != "/") {
                    // Router.push("/");
                    // }
                    // }
                } else {
                    if (user == null) {
                        // console.log("auto sign in");
                        processCredential(firebaseUser);
                    }
                }
            });
        return () => {
            console.log("cleanup needed?");
            // authStateListener.
        }
    }, []);

    useEffect(() => {
        // console.log("user?.appConfig effect");
        const nonLedgerApps = getApps()
            .filter((a) => a.name != "ledger")
            .map((a) => a.name);

        if (user?.appConfig == null) {
            // setLoading(false);
            return;
        }
        // console.log("user in user?.appConfig effect", user, "nonLA", nonLedgerApps);
        if (
            activeAppName != user.appConfig.app &&
            !nonLedgerApps.includes(user.appConfig.app)
        ) {
            initializeApp(user.appConfig.firebaseOptions);
            const auth = getAuth(getApp());
            auth.setPersistence(browserSessionPersistence);

            signInWithCustomToken(auth, user.appConfig.jwt).then(() => {
                // @ts-ignore
                setActiveAppName(user.appConfig.app);
                setSignedInToApp(true);
                setLoading(false);
            });
            //     return auth.currentUser.getIdTokenResult();
            // }).then((idTokenResult) => {
            //     // Confirm the user is an Admin.
            //     console.log(idTokenResult.claims)
            // })
        }
    }, [user?.appConfig]);

    return (
        <UserContext.Provider
            value={{
                isLoading: loading,
                user: user,
                signedInToApp: signedInToApp,
                setUser: setUser,
                setApp: setApp,
                processCredential: processCredential,
            }}
        >
            {props.children}
        </UserContext.Provider>
    );
};
