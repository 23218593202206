import { initializeApp } from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAVptIhFSnT27ueIuj3iEg8DXb2u5AY1Ow",
  authDomain: "live-megawatt-ledger.firebaseapp.com",
  projectId: "live-megawatt-ledger",
  storageBucket: "live-megawatt-ledger.appspot.com",
  messagingSenderId: "48907205579",
  appId: "1:48907205579:web:a5b6d18a9dd58c3bb4944e",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig, "ledger");