import { LMWAppConfig } from "../types/ledger";

export const getAvailableApps = async (token: string | undefined) => {

  if (!token) {
    throw new Error("Bad token");
  }
  const res = await fetch(
    "https://ledger.livemegawatt.com/get-available-apps?v=2",
    // "http://localhost:3000/get-available-apps?v=2",
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      redirect: "follow",
    }
  );

  if (!res) {
    throw new Error("Could not find apps");
  }

  let json;
  try {
    json = await res.json();
  } catch (e) {
    throw new Error("Bad response from ledger (no json)");
  }

  if (!Array.isArray(json)) {
    if(json.error){
      throw new Error(json.error);
    }
    throw new Error("Bad response from ledger");
  }

  return json as LMWAppConfig[];
};
