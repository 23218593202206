import { FC } from "react";
import { LoadingButton } from "@mui/lab";
import { CircularProgress, Container } from "@mui/material";

import "./AppStoreButtons.scss";

interface AppStoreButtonsProps {
  isLoading: boolean;
  name: string;
  android: string;
  ios: string;
}

const AppStoreButtons: FC<AppStoreButtonsProps> = (props) => {
  return props.isLoading ? (
    <div className="app-store-loading">
      <CircularProgress />
    </div>
  ) : (
    <div className="app-store-buttons">
      <h1>{props.name}</h1>
      <a href={props.ios} target="_blank">
        <img
          src={"/images/apple-app-store-button.png"}
          alt="Apple App Store"
        />
      </a>
      <a href={props.android} target="_blank">
        <img
          src={"/images/google-play-store-button.png"}
          alt="Google Play Store"
        />
      </a>
    </div>
  );
};

export default AppStoreButtons;
