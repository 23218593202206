import { User as FirebaseUser } from "firebase/auth";
import React from "react";
import { LMWAppConfig } from "../types/ledger";
import { User } from "../types/user";

export const UserContext = React.createContext<{
  isLoading: boolean;
  user: User | null;
  signedInToApp: boolean;
  setUser: (user: User | null) => void;
  setApp: (app: LMWAppConfig | null) => void;
  processCredential: (user: FirebaseUser) => true | any;
}>({
  isLoading: true,
  user: null,
  signedInToApp: false,
  setUser: () => {},
  setApp: () => {},
  processCredential: () => {},
});

export const UserProvider = UserContext.Provider;
