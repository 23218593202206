import React from 'react';
import "firebase/database";
import "firebase/storage";
import update from "immutability-helper";
import {Add, ArrowDownward, ArrowUpward, Clear, Delete, Remove, Segment} from "@mui/icons-material";
import {
    Button,
    CardContent,
    Grid,
    IconButton, Menu, MenuItem,
    Paper,
    Stack, TextareaAutosize,
    TextField,
    Typography
} from "@mui/material";
import {getStorage, uploadBytes, ref as ref_storage, getDownloadURL} from "@firebase/storage";
import {v4 as uuidv4} from 'uuid';
import {Box} from "@mui/system";
const reduce = require ("image-blob-reduce")();

function TextEditComponent({part, onChange}) {
    const handleChange = e =>
        onChange(update(part, {
            value: {$set: e.target.value}
        }))
    return <>
        <TextareaAutosize placeholder={"Type here to add text..."} value={part.value ?? ""} onChange={handleChange}/>
    </>;
}

function HeadingTextEditComponent({part, onChange}) {
    const handleChange = e =>
        onChange(update(part, {
            value: {$set: e.target.value}
        }))
    return <>
        <input type="text" placeholder={"Type here to add heading..."} className={"heading"} value={part.value ?? ""} onChange={handleChange}/>
    </>;
}

function LinkEditComponent({part, onChange}) {
    const handleChange = e =>
        onChange(update(part, {
            [e.target.name]: {$set: e.target.value}
        }))
    return <>
        <>
            <TextField type="text" fullWidth name="value" label={"Text"} value={part.value} onChange={handleChange}/>
        </>
        <>
            <TextField type="text" fullWidth name="url" label={"URL"} value={part.url} onChange={handleChange}/>
        </>
    </>;
}

function ImageEditComponent({part, onChange}) {
    const handleChange = async event => {

        onChange(update(part, {
            url: {$set: URL.createObjectURL(event.target.files[0])},
            file: {$set: event.target.files[0]},
            uploaded: {$set: false},
        }));
    };

    return <>
        <>
            {part.url && part.url !== "" ? <div>
                    <img src={part.url} style={{opacity: (part.uploaded === false ? 0.6 : 1)}}/>
                </div> :
                <input type="file" name="url" onChange={handleChange}/>}
        </>
    </>;
}

function EditComponent(props) {

    const {part} = props;

    const editComponents = {
        text: TextEditComponent,
        heading: HeadingTextEditComponent,
        link: LinkEditComponent,
        image: ImageEditComponent,
        movie: ImageEditComponent,
    };
    return editComponents[part.type] ? editComponents[part.type](props) : <></>;

}

export const ContentEditor = (options) => {

    let {content, setContent} = options;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [addPartAfter, setAddPartAfter] = React.useState(0);
    const open = Boolean(anchorEl);
    const handleAddButtonClick = (event, i) => {
        setAnchorEl(event.currentTarget);
        setAddPartAfter(i);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const updatePart = (i, part) => {
        setContent(update(content || [], {
            [i]: {$set: part}
        }))
    }

    const removePart = (e, i) => {
        if (!window.confirm("Are you sure you want to delete this section?")) return;
        e.preventDefault();
        const tempContent = [...(content || [])];
        tempContent.splice(i, 1);
        setContent(tempContent);
        return false;
    }
    const movePart = (e, i, direction) => {
        e.preventDefault();
        const to = i + (direction === "up" ? -1 : 1);
        const tempContent = [...(content || [])];
        tempContent.splice(to, 0, tempContent.splice(i, 1)[0])
        setContent(tempContent);
        return false;
    }
    const addPart = type => {
        handleClose();
        let part = {
            text: {
                value: "",
            },
            heading: {
                value: "",
            },
            link: {
                value: "",
                url: "",
            },
        }[type] || {};
        part.type = type;
        const tempContent = [...(content || [])];
        tempContent.splice(addPartAfter + 1, 0, part)
        setContent(tempContent);
        // setContent(update(content || [], {
        //     $push: [part]
        // }))
    }

    const ucfirst = title => (title && (title[0].toUpperCase() + title.substring(1))) || "";

    // const cancel = (e) => {
    //     onCancel();
    // }

    // console.log({content});

    // console.log("editor", options, content);

    return <Box className={"content-editor"}>
        {(content || []).map((part, i) =>
            <Box key={"part" + i} className={"section"}>
                    {/*<Typography variant="h5">{ucfirst(part.type)}</Typography><br />*/}
                    {/*<Grid container spacing={2}>*/}
                        {/*<Grid item xs={9}>*/}
                            <EditComponent part={part} onChange={newPart => updatePart(i, newPart)}/>
                        {/*</Grid>*/}
                        <Box className={"buttons"}>
                            <IconButton
                                id={"add-button-" + i}
                                aria-controls={open ? ("add-menu") : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={e => handleAddButtonClick(e, i)}
                            >
                                <Add />
                            </IconButton>
                            <IconButton disabled={i === 0}
                                        onClick={(e) => movePart(e, i, "up")}>
                                <ArrowUpward/>
                            </IconButton>
                            <IconButton disabled={i >= (content || []).length - 1}
                                        onClick={(e) => movePart(e, i, "down")}>
                                <ArrowDownward/>
                            </IconButton>
                            <IconButton onClick={(e) => removePart(e, i)}>
                                <Clear/>
                            </IconButton>
                        </Box>
                    {/*</Grid>*/}
            </Box>
        )}
        {(content || []).length === 0 && <Box className={"sections"}>
            <IconButton
                id={"add-button"}
                aria-controls={open ? ("add-menu") : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={e => handleAddButtonClick(e, 0)}
            >
                <Add />
            </IconButton>
        </Box>}

        <Menu
            id={"add-menu"}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            <MenuItem onClick={() => addPart("heading")}>Heading</MenuItem>
            <MenuItem onClick={() => addPart("text")}>Text</MenuItem>
            <MenuItem onClick={() => addPart("link")}>Link</MenuItem>
            <MenuItem onClick={() => addPart("image")}>Image</MenuItem>
            <MenuItem onClick={() => addPart("movie")}>Video</MenuItem>
        </Menu>
        <br/>


    </Box>;
}

// export default ContentEditor;

export const parseAndSaveContentEditorContent = async (content) => {
    // e.preventDefault();

    // if (!path || path === "")return;
    // setLoading(true);

    //Upload files
    let contentToSave = [];

    for (let part of content) {

        if (part.type === "image" && part.uploaded === false) {
            const storage = getStorage();
            const extensions = {
                "image/jpeg": "jpg",
                "image/png": "png",
            }
            const extension = extensions[part.file.type];
            if (!extension){
                alert("Unsupported image type");
                continue;
            }

            // Resize image first
            let blob = await reduce.toBlob(part.file, { max: 1200 });

            const uploadPath = 'uploads/' + uuidv4() + '.' + extension;
            const imageRef = ref_storage(storage, uploadPath);

            // part.fie -> blob
            const snapshot = await uploadBytes(imageRef, blob).catch((error) => {
                console.error(error);
                alert("Image upload failed...");
            });
            // console.log(snapshot);
            part = {
                type: "image",
                uploaded: !!snapshot,
                // "image-height": res.data.height,
                // "image-width": res.data.width,
            };
            if (snapshot) {
                part.path = uploadPath;
                part.url = await getDownloadURL(ref_storage(storage, uploadPath))
            }
            // console.log(part);

        }

        contentToSave.push(part);

    }

    // const data = contentToSave;
    // onSave(data);

    // setLoading(false);
    //
    // set(ref(getDatabase(), path), data).then(() => {
    //     setLoading(false);
    //     onSave(data);
    // }).catch((e) => {
    //     console.error(e);
    //     setLoading(false);
    //     alert("Something went wrong");
    // });

    return contentToSave;
}