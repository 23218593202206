import {Divider, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Typography} from "@mui/material";
import {
    Addchart,
    Article,
    Group, Newspaper,
} from "@mui/icons-material";
import {UserContext} from "../../contexts/user";
import {Box} from "@mui/system";
import React, {FC, ReactNode} from "react";
import {Link} from "react-router-dom";

type AppLinkProps = {
    app: string,
    to: string,
    children: ReactNode,
}
export const AppLink: FC<AppLinkProps> = ({app, to, children} : AppLinkProps) => {
    // @ts-ignore
    return <Link to={"/app/" + app + "/" + to}>
        {children}
    </Link>;
}

export const AppMenu: FC = () => {
    return <Box>

        <UserContext.Consumer>
            {(ctx) => <>
                <Link to="/app">
                    <h2>
                        {ctx.user?.appConfig?.info.name}
                    </h2>
                </Link>

                <MenuList>
                    <AppLink app={ctx.user?.appConfig.app} to={"texts"}>
                        <MenuItem>
                            <ListItemIcon>
                                <Article fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>App texts</ListItemText>
                        </MenuItem>
                    </AppLink>
                    <AppLink app={ctx.user?.appConfig.app} to={"news"}>
                        <MenuItem>
                            <ListItemIcon>
                                <Newspaper fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>News</ListItemText>
                        </MenuItem>
                    </AppLink>
                    <AppLink app={ctx.user?.appConfig.app} to={"notifications"}>
                        <MenuItem>
                            <ListItemIcon>
                                <Addchart fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>Maintenance notifications</ListItemText>
                        </MenuItem>
                    </AppLink>
                    <Divider/>
                    <AppLink app={ctx.user?.appConfig.app} to={"users"}>
                        <MenuItem>
                            <ListItemIcon>
                                <Group fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>User management</ListItemText>
                        </MenuItem>
                    </AppLink>
                </MenuList>
            </>}
        </UserContext.Consumer>
    </Box>;
};