import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import Colors from '../components/common/Color/Color';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: Colors.blue[500],
    },
    secondary: {
      main: Colors.red[500],
    },
    error: {
      main: red.A400,
    },
  },
  spacing: 8,

  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // No more ripple, on the whole application
      },
    },
  },
});

export default theme;