import {Avatar, Container, Paper} from "@mui/material";
import {NavigationAvatar} from "./NavigationAvatar";
import {Link} from "react-router-dom";
import {Box} from "@mui/system";

export const NavigationBar = () => {

    return <Paper sx={{p: 1}}>
        <Container>
            <Box sx={{display: 'flex', justifyContent: "space-between", alignItems: "center"}}>
                <Link to="/">
                    <img
                        src={"/images/logo-lmw.png"}
                        alt="Live Megawatt"
                        height={50}
                    />
                </Link>
                <NavigationAvatar/>
            </Box>
        </Container>
    </Paper>;
};
