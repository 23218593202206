import {Alert, Dialog, TextField, DialogTitle, DialogContent, Grid, Link, Button} from "@mui/material";
import React, {useContext, useState} from "react";
import {
    browserSessionPersistence,
    getAuth,
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    signInWithPopup,
    sendPasswordResetEmail,
} from "firebase/auth";
import {getApp} from "firebase/app";
import {UserContext} from "../../../contexts/user";
import {LoadingButton} from "@mui/lab";

const auth = getAuth(getApp("ledger"));
auth.setPersistence(browserSessionPersistence);

export const SignInModal = (props: any) => {
    const [error, setError] = useState<string | null>(null);
    const [email, updateEmail] = useState("");
    const [password, updatePassword] = useState("");
    const [loading, setLoading] = useState(false);

    const userContext = useContext(UserContext);

    const resetForm = () => {
        setLoading(false);
        setError("");
        updateEmail("");
        updatePassword("");
    }

    const resetPassword = (e) => {

        e.preventDefault();
        setLoading(true);

        sendPasswordResetEmail(auth, email).then(() => {
            setError("If the e-mail address is known, an e-mail will be sent containing a link to reset your password.");
        }).catch((e: any) => {
            if (e && e.message) {
                return setError(e.message);
            }
            return setError("Something went wrong");
        }).finally(() => {
            setLoading(false);
        });

    }

    const signInWithGoogle = async () => {
        const provider = new GoogleAuthProvider();

        const result = await signInWithPopup(auth, provider);

        const credential = GoogleAuthProvider.credentialFromResult(result);
        if (credential == null) {
            return setError("Error signing in");
        }

        const res = await userContext.processCredential(result.user);
        if (!res) {
            return setError("Something went wrong");
        }
        props.onSignIn();
    };

    const signInWithEmail = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        signInWithEmailAndPassword(auth, email, password).then(credential => {
            return userContext.processCredential(credential.user);
        }).then((res: any) => {
            props.onSignIn();
            resetForm();
        }).catch((e: any) => {
            if (e && e.message) {
                return setError(e.message);
            }
            return setError("Something went wrong");
        }).finally(() => {
            setLoading(false);
        });
        return false;
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}
                className={"sign-in-modal"}>
            <DialogTitle>
                Sign in
            </DialogTitle>
            <DialogContent>
                <form onSubmit={signInWithEmail}>
                    {error && (
                        <Alert severity="error" sx={{mt: 2}}>
                            {error}
                        </Alert>
                    )}
                    {/*<Button variant="outlined" sx={{mt: 3}} onClick={signInWithGoogle}>*/}
                    {/*    Sign in with Google*/}
                    {/*</Button>*/}
                    {/*<div className="sign-in-or">or</div>*/}
                    <TextField
                        required
                        fullWidth
                        margin="normal"
                        id="email"
                        name="email"
                        label="Email"
                        autoFocus
                        autoComplete="email"
                        value={email}
                        onChange={(e) => updateEmail(e.target.value)}
                    />
                    <TextField
                        required
                        fullWidth
                        margin="normal"
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => updatePassword(e.target.value)}
                    />
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={loading}
                        sx={{mt: 3, mb: 2}}
                    >
                        Sign in
                    </LoadingButton>
                    <Button onClick={resetPassword} style={{float: "right", marginTop: 30}}>
                        Forgot password
                    </Button>
                </form>
            </DialogContent>
        </Dialog>
    );
}
