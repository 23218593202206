import React, {FC, useState} from "react";
import {
    Alert, Button,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {getAuth} from "firebase/auth";
import {useAuth} from "../../App";

type RemoveUserDialogProps = {
    open: boolean;
    uids?: string[];
    onClose: () => React.Dispatch<boolean>;
};

export const RemoveUserDialog: FC<RemoveUserDialogProps> = ({
                                                                open,
                                                                uids,
                                                                onClose,
                                                            }: RemoveUserDialogProps) => {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const auth = useAuth();

    const resetForm = () => {
        setError("");
    };

    const onSave = async (e) => {
        e.preventDefault();

        setLoading(true);
        setError(null);

        for (let uid of uids) {

            const app = auth.user.appConfig.app;
            const token = await getAuth().currentUser.getIdToken();
            await fetch(
                "https://ledger.livemegawatt.com/remove-user",
                // "http://localhost:3000/remove-user",
                {
                    headers: {
                        Authorization: "Bearer " + token,
                        "Content-Type": "application/json",
                    },
                    method: "POST",
                    body: JSON.stringify({
                        uid,
                        app,
                    }),
                    redirect: "follow",
                }
            )
                .then((res) => res.json())
                .then((result) => {
                    console.log(result);
                    switch (result.status) {
                        case "success":
                            break;
                        case "unknown":
                        case "error":
                        default:
                            setError("Unable to remove this user to the app.");
                            break;
                    }
                })
                .finally(() => {
                    setLoading(false);
                });

        }

        resetForm();
        onClose();
        // Refresh page
        window.location.reload();

        return false;
    };

    // getAuth().currentUser.getIdToken().then(console.log);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Remove {uids?.length > 1 ? (uids.length + " users") : "user"} from app</DialogTitle>
            <DialogContent>
                {error && (
                    <Alert severity="error" sx={{mt: 2}}>
                        {error}
                    </Alert>
                )}
                <Button onClick={onClose}>
                    Cancel
                </Button>
                <form onSubmit={onSave}>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="error"
                        loading={loading}
                        sx={{mt: 3, mb: 2}}
                    >
                        Remove
                    </LoadingButton>
                </form>
            </DialogContent>
        </Dialog>
    );
};
