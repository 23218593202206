import {
  ContentEditor,
  parseAndSaveContentEditorContent,
} from "../../../components/webpanel/ContentEditor";
import React, { FC, useEffect, useState } from "react";
import { get, getDatabase, ref, set } from "@firebase/database";
import update from "immutability-helper";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Edit } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

export type InfoText = {
  label: string; //label = key
  isEmpty: boolean;
  content: any;
};

const Texts: FC = () => {
  const [items, setItems] = useState<{ [key: string]: InfoText }>({});
  const [activeItem, setActiveItem] = useState<InfoText | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    get(ref(getDatabase(), "info")).then(function (snapshot) {
      if (snapshot.exists()) {
        let items = {};
        let contents = snapshot.val();
        for (let key of Object.keys(contents)) {
          let content = contents[key];
          let item = {
            label: key,
            content: content,
            isEmpty: false,
          };
          if (
            content.length == 1 &&
            (!content[0].value || content[0].value == "")
          ) {
            item.isEmpty = true;
          }
          items[key] = item;
        }
        setItems(items);
      }
    });
  }, []);

  const save = async (e: any) => {
    e.preventDefault();
    console.log({ activeItem });
    setLoading(true);
    const item = await parseAndSaveContentEditorContent(activeItem.content);

    set(ref(getDatabase(), "info/" + activeItem.label), item)
      .then(() => {
        setLoading(false);
        setItems(
          update(items, {
            [activeItem.label]: {
              content: { $set: item },
            },
          })
        );
        setActiveItem(null);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        alert("Something went wrong");
      });
  };

  const getTitle = (title: string) => {
    title = title.replace(/-/g, " ");
    return title[0].toUpperCase() + title.substring(1);
  };

  return (
    <>
      <h2>Texts</h2>

      <Table>
        <TableBody>
          {Object.keys(items).map((label) => (
            <TableRow key={label}>
              <TableCell
                sx={{ color: items[label].isEmpty ? "#ccc" : "inherit" }}
              >
                {getTitle(label)}
              </TableCell>
              <TableCell align={"right"}>
                <Button
                  onClick={() => {
                    setActiveItem(items[label]);
                  }}
                >
                  <Edit />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <br />

      {activeItem && (
        <Dialog
          open={!!activeItem}
          onClose={() => setActiveItem(null)}
          fullWidth
        >
          <DialogTitle>{getTitle(activeItem.label)}</DialogTitle>
          <DialogContent>
            <form onSubmit={save}>
              <ContentEditor
                content={activeItem.content}
                setContent={(content) =>
                  setActiveItem(
                    update(activeItem, {
                      content: { $set: content },
                    })
                  )
                }
              />

              <br />
              <Stack direction="row" justifyContent="flex-end" spacing={2}>
                <Button onClick={() => setActiveItem(null)} disabled={loading}>
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  variant={"contained"}
                  loading={loading}
                >
                  Save
                </LoadingButton>
              </Stack>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default Texts;
