import { FC, useEffect, useState } from "react";
import {
  get,
  getDatabase,
  orderByChild,
  query,
  ref,
  remove,
  set,
} from "@firebase/database";
import {
  Button,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import { EditNotificationDialog } from "./EditNotificationDialog";
// @ts-ignore
import { v4 as uuidv4 } from "uuid";

const { DateTime } = require("luxon");

export type ChartNotification = {
  title: string;
  start?: string;
  end?: string;
  startDayjs?: typeof DateTime;
  endDayjs?: typeof DateTime;
  startSeconds?: number;
  id: string;
};

const Notifications: FC = () => {
  const [items, setItems] = useState<{ [key: string]: ChartNotification }>({});
  const [editItem, setEditItem] = useState<ChartNotification | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    get(
      query(ref(getDatabase(), "notifications"), orderByChild("startSeconds"))
    )
      .then((snapshot) => {
        if (snapshot.exists()) {
          let items = snapshot.val();
          for (let id of Object.keys(items)) {
            items[id].id = id;
            if (items[id].start)
              items[id].startDayjs = DateTime.fromISO(items[id].start, {
                zone: "UTC",
              });
            if (items[id].end)
              items[id].endDayjs = DateTime.fromISO(items[id].end, {
                zone: "UTC",
              });
          }
          setItems(items);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSave = (item: ChartNotification) => {
    setItems({ ...items, [item.id]: item });
    setEditItem(null);
  };

  const deleteItem = (item: ChartNotification) => {
    if (!item || !item.id || item.id === "") return;
    if (!confirm("Are you sure you want to delete this notification?")) return;
    remove(ref(getDatabase(), "notifications/" + item.id)).then(() => {
      let newItems = { ...items };
      delete newItems[item.id];
      setItems(newItems);
    });
  };

  const addItem = () => {
    setEditItem({
      title: "",
      id: uuidv4(),
    });
  };

  return (
    <>
      <h2>Notifications</h2>

      {loading ? (
        <CircularProgress />
      ) : (
        <Table aria-label="notifications table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "300px" }}>Title</TableCell>
              <TableCell>From</TableCell>
              <TableCell>To</TableCell>
              {/*<TableCell>Category</TableCell>*/}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items &&
              Object.values(items)
                .sort((a, b) => (a.startSeconds ?? 0) - (b.startSeconds ?? 0))
                .map((item) => (
                  <TableRow key={item["id"]}>
                    <TableCell>{item.title}</TableCell>
                    <TableCell>
                      {item.startDayjs?.toFormat("dd-LL-yyyy HH:mm")}
                    </TableCell>
                    <TableCell>
                      {item.endDayjs?.toFormat("dd-LL-yyyy HH:mm")}
                    </TableCell>
                    {/*<TableCell/>*/}
                    <TableCell>
                      <IconButton
                        aria-label="edit"
                        onClick={() => setEditItem(item)}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() => deleteItem(item)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      )}

      <br />
      <br />

      <Button variant="contained" onClick={addItem}>
        <Add /> New notification
      </Button>

      <EditNotificationDialog
        item={editItem}
        handleClose={() => setEditItem(null)}
        handleSave={(item) => handleSave(item)}
      />
    </>
  );
};

export default Notifications;
