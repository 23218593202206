import {
    ContentEditor,
    parseAndSaveContentEditorContent,
} from "../../../components/webpanel/ContentEditor";
import React, {FC, useEffect, useState} from "react";
import {
    get,
    getDatabase,
    orderByChild,
    query,
    ref,
    set,
    push,
    remove,
} from "@firebase/database";
import update from "immutability-helper";

const {DateTime} = require("luxon");
import {
    Button,
    Card,
    CardContent,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Grid,
    Paper,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import {Edit} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";

export type NewsItem = {
    key: string;
    i: number;
    subject: string;
    createdAt: string;
    createdAtI: number;
    updatedAt?: string;
    updatedAtI?: number;
    active: boolean;
    content: any;
};

const News: FC = () => {
    const [items, setItems] = useState<NewsItem[]>([]);
    const [activeItem, setActiveItem] = useState<NewsItem | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const addItem = () => {
        setActiveItem({
            subject: "",
            createdAt: "",
            createdAtI: 0,
            active: false,
            content: [],
            key: "new",
            i: 0,
        });
    };

    useEffect(() => {
        get(
            query(ref(getDatabase(), "newsitems"), orderByChild("createdAtI"))
        ).then(function (snapshot) {
            if (snapshot.exists()) {
                let items = [];
                snapshot.forEach(function (child) {
                    let item = child.val();
                    item.key = child.key;
                    items.push(item);
                });
                setItems(items);
            }
        });
    }, []);

    const save = async (e: any) => {
        e.preventDefault();
        let _activeItem = {...activeItem};
        setLoading(true);
        const newItem = _activeItem.key === "new";
        const content = await parseAndSaveContentEditorContent(
            _activeItem.content || []
        );
        _activeItem = update(_activeItem, {
            content: {$set: content},
            updatedAt: {$set: DateTime.now().toISO()},
            updatedAtI: {$set: -DateTime.now().toMillis()},
        });

        if (newItem) {
            _activeItem = update(_activeItem, {
                createdAt: {$set: DateTime.now().toISO()},
                createdAtI: {$set: -DateTime.now().toMillis()},
            });
        }

        // const newPostKey = push(child(ref(db), 'posts')).key;
        const itemRef = ref(
            getDatabase(),
            "newsitems" + (newItem ? "" : "/" + _activeItem.key)
        );
        const action = newItem ? push : set;

        action(itemRef, _activeItem)
            .then((result) => {
                if (result) {
                    _activeItem.key = result.key;
                }
                setLoading(false);
                if (newItem) {
                    setItems(
                        update(items, {
                            $unshift: [_activeItem],
                        })
                    );
                } else {
                    setItems(
                        update(items, {
                            $apply: items => items.map(item => {
                                return item.key === itemRef.key ? _activeItem : item
                            }),
                        })
                    )
                }
                setActiveItem(null);
            })
            .catch((e) => {
                console.error(e);
                setLoading(false);
                alert("Something went wrong");
            });
    };

    const deleteActiveItem = (e) => {
        e.preventDefault();
        const itemRef = ref(getDatabase(), "newsitems/" + activeItem.key);

        if (
            confirm(
                "Are you sure you want to delete this item? This can not be undone."
            )
        ) {
            setLoading(true);
            remove(itemRef)
                .then(() => {
                    setLoading(false);

                    window.location.reload(); //@TODO: delete the item from items
                })
                .catch((e) => {
                    console.error(e);
                    setLoading(false);
                    alert("Something went wrong");
                });
        }
    };

    return (
        <>
            <h2>News</h2>

            <Table>
                <TableBody>
                    {items.map((item) => (
                        <TableRow key={item.key}>
                            <TableCell sx={{width: 120}}>
                                {DateTime.fromISO(item.createdAt).toLocaleString()}
                            </TableCell>
                            <TableCell sx={{color: !item.active ? "#ccc" : "inherit"}}>
                                {item["subject"]}
                            </TableCell>
                            <TableCell sx={{width: 120, textAlign: "right"}}>
                                {!item.active && <Chip label="Hidden" size="small"/>}
                            </TableCell>
                            <TableCell sx={{width: 40}} align={"right"}>
                                <Button
                                    onClick={() => {
                                        setActiveItem(item);
                                    }}
                                >
                                    <Edit/>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <br/>
            <br/>

            <Button variant="contained" onClick={addItem} disabled={false}>
                Add news item
            </Button>

            {activeItem && (
                <Dialog
                    open={!!activeItem}
                    onClose={() => setActiveItem(null)}
                    fullWidth
                >
                    <form onSubmit={save}>
                        <DialogTitle>
                            <strong>Title</strong>
                            <br/>
                            <TextField
                                fullWidth
                                value={activeItem.subject ?? ""}
                                onChange={(e) => {
                                    setActiveItem(
                                        update(activeItem, {
                                            subject: {$set: e.target.value},
                                        })
                                    );
                                }}
                            />
                        </DialogTitle>
                        <DialogContent>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={activeItem.active}
                                            onChange={(e) => {
                                                setActiveItem(
                                                    update(activeItem, {
                                                        active: {$set: e.target.checked},
                                                    })
                                                );
                                            }}
                                        />
                                    }
                                    label="Active"
                                />
                            </FormGroup>

                            <br/>
                            <Paper variant={"outlined"} sx={{p: 1}}>
                                <ContentEditor
                                    content={activeItem.content}
                                    setContent={(content) =>
                                        setActiveItem(
                                            update(activeItem, {
                                                content: {$set: content},
                                            })
                                        )
                                    }
                                />
                            </Paper>
                            <br/>

                            <Stack direction="row" justifyContent="flex-end" spacing={2}>
                                <Button
                                    onClick={deleteActiveItem}
                                    color={"error"}
                                    disabled={loading}
                                >
                                    Delete
                                </Button>
                                <Button onClick={() => setActiveItem(null)} disabled={loading}>
                                    Cancel
                                </Button>
                                <LoadingButton
                                    type="submit"
                                    variant={"contained"}
                                    loading={loading}
                                >
                                    Save
                                </LoadingButton>
                            </Stack>
                        </DialogContent>
                    </form>
                </Dialog>
            )}

            {/*{addItem && <Dialog open={!!addItem} onClose={() => setAddItem(null)} fullWidth>*/}
            {/*    <DialogTitle>*/}
            {/*        {addItem.subject}*/}
            {/*    </DialogTitle>*/}
            {/*    <DialogContent>*/}

            {/*    </DialogContent>*/}
            {/*</Dialog>}*/}
        </>
    );
};

export default News;
