import { Logout, PersonAdd, Settings } from "@mui/icons-material";
import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { useState } from "react";
import { UserContext } from "../../../contexts/user";
import { SignInModal } from "../Modal/SigninModal";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../pages/App";

type NavigationAvatarProps = {};
export const NavigationAvatar = (props: NavigationAvatarProps) => {
  const navigate = useNavigate();
  const auth = useAuth();

  const [showModal, setShowModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = () => {
    getAuth(getApp("ledger"))
      .signOut()
      .then(() => navigate("/"));
  };

  return (
    <>
      {auth.isLoading ? (
        <CircularProgress />
      ) : auth.user == null ? (
        <Button variant="contained" onClick={() => setShowModal(true)}>
          Login
        </Button>
      ) : (
        <>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>
              {(auth.user.name ?? auth.user.email).substring(0, 1)}
            </Avatar>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {auth.user && auth.user.apps.length > 0 ? (
              <>
                {/*<MenuItem>*/}
                {/*  <Avatar /> {ctx.user.name ?? "Profile"}*/}
                {/*</MenuItem>*/}
                {/*<Divider />*/}
                {auth.user.apps.map((app, i) => (
                  <Link to={"/app/" + app.app} key={"app" + i}>
                    <MenuItem key={app.app}>{app.info.name}</MenuItem>
                  </Link>
                ))}
                <Divider />
              </>
            ) : (
              <></>
            )}
            <MenuItem onClick={signOut}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </>
      )}
      <SignInModal
        open={showModal}
        onSignIn={() => setShowModal(false)}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};
