import { Card, Container } from "@mui/material";
import React, { useEffect } from "react";
import { useAuth } from "../App";
import { useNavigate } from "react-router";
import { NavigationBar } from "../../components/common/NavigationBar/NavigationBar";

export const Home = () => {
  let auth = useAuth();
  let navigate = useNavigate();

//   useEffect(() => {
//     if (auth.signedInToApp) navigate("/app");
//   }, [auth.signedInToApp]);

  return (
    <>
      <NavigationBar />
      <Container sx={{ pt: 2 }}>
        <Card sx={{ p: 2 }}>
          <p>Welcome to the Live Megawatt App Portal!</p>

          <p>Sign in to continue...</p>
        </Card>
      </Container>
    </>
  );
};
