import {
  Card,
  CardContent,
  CardMedia,
  Container,
  Paper,
  Typography,
} from "@mui/material";
import { FC, useContext } from "react";
import { NavigationBar } from "../../components/common/NavigationBar/NavigationBar";
import { UserContext } from "../../contexts/user";
import { LMWAppConfig } from "../../types/ledger";
import { Link, Outlet } from "react-router-dom";
import { Box } from "@mui/system";
import { AppMenu } from "./app-menu";

export const SelectAppPage: FC = () => {
  const userContext = useContext(UserContext);

  const selectApp = (app: LMWAppConfig) => {
    userContext.setApp(app);
  };

  return (
    <>
      <NavigationBar />
      <Container>
        <Box sx={{ m: 2, display: "flex" }}>
          <Paper
            elevation={2}
            // variant={"outlined"}
            sx={{ flexGrow: 1, p: 2 }}
            component="main"
          >
            <h1>Select app</h1>
            <UserContext.Consumer>
              {(ctx) => (
                <div>
                  {ctx.user?.apps.map((app: LMWAppConfig) => (
                    <Link
                      to={"/app/" + app.app}
                      onClick={() => selectApp(app)}
                      key={app.app}
                    >
                      <Card sx={{ marginBottom: "10px" }}>
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            {app.info.name}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {app.info.description}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Link>
                  ))}
                </div>
              )}
            </UserContext.Consumer>
          </Paper>
        </Box>
      </Container>
    </>
  );
};
