import { Box, Container } from "@mui/material";
import { FC } from "react";

const FullPageLoader: FC<{ message?: string | null }> = (props) => {
  return (
    <Container sx={{ p: 10, textAlign: "center" }}>
      <Box>{props.message ?? "Loading ..."}</Box>
    </Container>
  );
};

export default FullPageLoader;
