import { FC, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AppStoreButtons from "../../components/common/AppStoreButtons/AppStoreButtons";
import { NavigationBar } from "../../components/common/NavigationBar/NavigationBar";
import { useAuth } from "../App";

export const appStoreLinks: Record<
  string,
  { name: string; android: string; ios: string }
> = {
  "de-groene-delta": {
    name: "De Groene Delta",
    android:
      "https://play.google.com/store/apps/details?id=com.livemegawatt.degroenedelta",
    ios: "https://apps.apple.com/app/de-groene-delta/id1624655138",
  },
  "zeewolde": {
    name: "Windpark Zeewolde",
    android:
      "https://play.google.com/store/apps/details?id=com.livemegawatt.zeewolde",
    ios: "https://apps.apple.com/nl/app/windpark-zeewolde/id1663639407",
  },
};

const AppStoreReferral: FC = (props) => {
  const routerParams = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (
      typeof routerParams?.app !== "string" ||
      typeof appStoreLinks[routerParams.app]?.android !== "string"
    ) {
      return navigate("/");
    } else if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
      window.location.href = appStoreLinks[routerParams.app].android;
    } else if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
      window.location.href = appStoreLinks[routerParams.app].ios;
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <>
      <AppStoreButtons
        isLoading={isLoading}
        name={appStoreLinks[routerParams.app].name}
        android={appStoreLinks[routerParams.app].android}
        ios={appStoreLinks[routerParams.app].ios}
      />
    </>
  );
};

export default AppStoreReferral;
